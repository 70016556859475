<template>
  <div class="mcr-image" :class="classes" @click="onClick">
    <img :src="previewSrc" :data-src="src" class="lazyload" :style="styles" />
    <dynamic-html v-if="caption" name="mcr-image-caption" :html="caption" tag="span"></dynamic-html>
  </div>
</template>

<script>
import DynamicHtml from '@common/elements/familyWebsite/DynamicHtml';
import {getGalleryImageData, getImageLazyLoadPlaceholderSrc, getImageSrc} from '@common/utils/utils';

export default {
  components: {DynamicHtml},
  props: {
    imageStyle: String,
    image: String,
    caption: String,
    imageWidth: String,
  },
  computed: {
    src() {
      return getImageSrc(this.image);
    },
    previewSrc() {
      return getImageLazyLoadPlaceholderSrc(this.image);
    },
    classes() {
      const classes = this.imageStyle.split(' ');
      if (this.isMobile && !classes.includes('full')) {
        return [...classes, 'full'];
      }
      return classes;
    },
    isFull() {
      return this.classes.includes('full');
    },
    styles() {
      if (this.imageWidth && !this.isFull) {
        return {'max-width': `${this.imageWidth}px`};
      }
      return {};
    },
    isMobile() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.mobile;
    },
  },
  methods: {
    onClick() {
      const imageData = getGalleryImageData(0, this.src, this.caption, {}, !this.caption);
      this.$store.commit('setGalleryItemsState', [imageData]);
      this.$store.dispatch('displayGalleryAction', 0);
    },
  },
};
</script>
<style scoped lang="scss">
.mcr-image {
  float: right;
  margin-bottom: 30px;
  margin-left: 30px;
  clear: right;
  margin-top: 6px;
  display: table;

  img {
    max-width: 500px;
    border: var(--report-gallery-border);
    box-shadow: var(--report-gallery-shadow);
    transition: all 0.5s ease;
    cursor: pointer;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;

    &:hover {
      border-color: var(--report-image-hover-border-color);
    }
  }

  span {
    display: table-caption;
    caption-side: bottom;
    max-width: 500px;
    text-align: center;
    font-style: normal;
    color: #777;
    font-size: 0.8em;
    &::v-deep {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.small {
    img,
    span {
      max-width: 240px;
    }
  }

  &.full {
    clear: both;
    float: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 0;
    margin-top: 25px;
    margin-bottom: 25px;

    img {
      max-width: 100%;
    }

    span {
      max-width: 100%;
      width: 100%;
      margin-top: 12px;
    }
  }
}
</style>
